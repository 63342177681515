@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
    margin: 0;
    padding: 0;
    hyphens: auto;
}

a,
a:visited,
a:active {
    text-decoration: none;
}

#b2x-register .custom-input-class {
    @apply border rounded border-grey-200;
    border-radius: 10px;
    padding: 18px !important;
}

:root {
    color-scheme: light;

    --radius-outer: 0.375rem;
    --radius-inner: 0.2rem;
    --radius-button: 0;

    --paper: #FFFFFF;
    --grey-100: #F9F9FC;
    --grey-200: #DFE0E7;
    --grey-300: #B0B4C6;
    --grey-400: #787C93;
    --grey-500: #272A3C;

    color: var(--grey-500);
    font-family: var(--font);

    --red: #DE2E21;
    --orange: #FFA000;
    --blue: #72a4e1;
    --green: #43A047;

    /* used very exceptionally, e.g. for text that is placed on an image,
       therefore it should not 'switch' when changing the color scheme */
    --persistent-white: #FFFFFF;
    --persistent-black: #191B29;
    --persistent-grey-500: #272A3C;
    --health-warning-size: calc(attr(data-health-warning-size) * 1vh);
}

:root[data-corners=round] {
    --radius-outer: 1.75rem;
    --radius-inner: 1.25rem;
    --radius-button: 9999px;
}

:root[data-scheme=dark] {
    color-scheme: dark;

    --red: #C23730;
    --orange: #DD8C06;
    --paper: #191B29;
    --grey-100: #242631;
    --grey-200: #54565F;
    --grey-300: #AAAAAF;
    --grey-400: #E7E7E8;
    --grey-500: #FAFAFA;
}

:root[data-accent=violet], .accent-violet {
    --accent-100: #F9F5FB;
    --accent-200: #E6D6ED;
    --accent-300: #B383CA;
    --accent-400: #8031A7;
    --accent-500: #662786;

    --contrast-100: var(--grey-500);
    --contrast-200: var(--grey-500);
    --contrast-300: var(--grey-500);
    --contrast-400: var(--grey-100);
    --contrast-500: var(--grey-100);
}

:root[data-scheme=dark][data-accent=violet] {
    --accent-100: #2A1C3F;
    --accent-200: #472562;
    --accent-300: #6F3096;
    --accent-400: #BC7BD5;
    --accent-500: #E2D0E6;
}

:root[data-accent=teal], .accent-teal {
    --accent-100: #F0FBFA;
    --accent-200: #CCF0EE;
    --accent-300: #66D1CB;
    --accent-400: #009089;
    --accent-500: #00726C;

    --contrast-100: var(--grey-500);
    --contrast-200: var(--grey-500);
    --contrast-300: var(--persistent-grey-500);
    --contrast-400: var(--grey-100);
    --contrast-500: var(--grey-100);
}

:root[data-scheme=dark][data-accent=teal] {
    --accent-100: #133741;
    --accent-200: #0E5F63;
    --accent-300: #03A5A0;
    --accent-400: #3FC3B5;
    --accent-500: #91E2D3;
}

:root[data-accent=blue], .accent-blue {
    --accent-100: #ECF2FA;
    --accent-200: #D9E5F4;
    --accent-300: #8CB2DF;
    --accent-400: #3A71B5;
    --accent-500: #214E84;

    --contrast-100: var(--grey-500);
    --contrast-200: var(--grey-500);
    --contrast-300: var(--grey-500);
    --contrast-400: var(--grey-100);
    --contrast-500: var(--grey-100);
}

:root[data-scheme=dark][data-accent=blue] {
    --accent-100: #1E2C48;
    --accent-200: #2B4871;
    --accent-300: #3968A5;
    --accent-400: #8BB3DA;
    --accent-500: #E9F2F8;
}

:root[data-accent=yellow], .accent-yellow {
    --accent-100: #FDF7E5;
    --accent-200: #FBEECC;
    --accent-300: #F2CC66;
    --accent-400: #EAAA00;
    --accent-500: #BB8800;

    --contrast-100: var(--grey-500);
    --contrast-200: var(--grey-500);
    --contrast-300: var(--persistent-grey-500);
    --contrast-400: var(--persistent-grey-500);
    --contrast-500: var(--grey-100);
}

:root[data-scheme=dark][data-accent=yellow] {
    --accent-100: #362805;
    --accent-200: #6D5004;
    --accent-300: #C89201;
    --accent-400: #F2CC66;
    --accent-500: #FAEEA0;
}

:root[data-font=font-open-sans] {
    --font: Open Sans, sans-serif;
}

:root[data-font=font-cambay] {
    --font: Cambay, sans-serif;
}

:root[data-font=font-roboto] {
    --font: Roboto, sans-serif;
}

:root[data-font=font-cairo] {
    --font: Cairo;
}

:root[data-font=font-noto-sans-arabic] {
    --font: Noto Sans Arabic;
}

:root[data-font=font-kaiti] {
    --font: KaiTi;
}

:root[data-font=font-heiti] {
    --font: HeiTi;
}

:root[data-font=font-bayon] {
    --font: Bayon;
}

:root[data-font=font-kantumruy] {
    --font: Kantumruy;
}

:root[data-font=font-padauk] {
    --font: Padauk;
}

:root[data-font=font-noto-sans-myanmar] {
    --font: Noto Sans Myanmar;
}
